import React, { Component } from 'react';
import logo from '../images/logo.svg';
import { Link } from 'react-router-dom';

class BetaClosed extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className='beta-screen'>
                <div className="beta-screen-container">
                    <img className="logo" src={logo} alt="tender-notify-logo" />
                    <h2 className='title'>Beta phase has closed</h2>
                    <p className='description'>Thank you for participating in the Tender Notify Beta! We are currently making enhancements</p>
                    <p className='description'>based on your feedback. Stay tuned - general access will open shortly!</p>
                    <p className='mobile-description'>Thank you for participating in the Tender Notify Beta! We are currently making enhancements based on your feedback. Stay tuned - general access will open shortly!</p>
                    <Link to={"/"}>
                        <button className='button'>RETURN TO SITE</button>
                    </Link>
                </div>
            </div>
        );
    }
}

export default BetaClosed;